/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import auth from "@/auth/authService";

// import firebase from 'firebase/app'
// import 'firebase/auth'

Vue.use(Router);

const router = new Router({
      mode: 'history',
      hash: false,

    base: process.env.Base_Url,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [{
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: "",
            component: () =>
                import ("./layouts/main/Main.vue"),
            children: [
                // =============================================================================
                // Pages Routes
                // =============================================================================

                {
                    path: "/",
                    name: "home",
                    component: () =>
                        import ("./views/HomeV3.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Home"
                            /// authRequired: true
                    }
                },
                {
                    path: "/home",
                    name: "home",
                    component: () =>
                        import ("./views/Home.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Home"
                            /// authRequired: true
                    }
                },
                // =============================================================================
                // Pages Routes
                // =============================================================================


                {
                    path: "/OurPackages/:ID?",
                    name: "OurPackages",
                    component: () =>
                        import ("@/views/Patient/Packages/OurPackages.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Our Packages", active: true }
                        ],
                        pageTitle: "OurPackages",
                        rule: "anonymous"
                    }
                },

                {
                    path: "/Patient/Home",
                    name: "LandingPage",
                    component: () =>
                        import ("@/views/Patient/Home.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Landing Page", active: true }
                        ],
                        pageTitle: "LandingPage",
                        rule: "patient"
                    }
                },

                {
                    path: "/Patient/PatientMedicalHistory/:ID?",
                    name: "PatientMedicalHistory",
                    component: () =>
                        import ("@/views/Patient/PatientMedicalHistory.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "PatientMedicalHistory", active: true }
                        ],
                        pageTitle: "Patient Medical History",
                        rule: "patient"
                    }
                },
                {
                    path: "/Patient/ReservationSession/:ID?",
                    name: "ReservationSession",
                    component: () =>
                        import ("@/views/Patient/Reservation/ReservationSession.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "ReservationSession", active: true }
                        ],
                        pageTitle: "Reservation Session",
                        rule: "patient"
                    }
                },

                {
                    path: "/PatientReservationSession/:StatusID?/:ReservationID?",
                    name: "PatientReservationSession",
                    component: () =>
                        import ("@/views/Patient/Reservation/ResevationTabs.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "ReservationSession", active: true }
                        ],
                        pageTitle: "Reservation Session",
                        rule: "patient"
                    }
                },
                {
                    path: "/PatientPaymentPending/",
                    name: "PatientPaymentPending",
                    component: () =>
                        import ("@/views/Patient/Payment/PaymentTabs.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "PatientPaymentPending", active: true }
                        ],
                        pageTitle: "Patient Pending Payment ",
                        rule: "patient"
                    }
                },
                {
                    path: "/Patient/PrescriptionsList",
                    name: "PrescriptionsList",
                    component: () =>
                        import ("@/views/Patient/Reservation/PrescriptionsList.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "PrescriptionsList", active: true }
                        ],
                        pageTitle: "Prescriptions",
                        rule: "patient"
                    }
                },
                {
                    path: "/Patient/PrescriptionPDF/:ID?",
                    name: "PrescriptionPDF",
                    component: () =>
                        import ("@/views/Patient/Reservation/PrescriptionPDF.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "PrescriptionPDF", active: true }
                        ],
                        pageTitle: "Prescription",
                        rule: "patient"
                    }
                },
                {
                    path: "/Patient/ReservationSessionPrescriptions/:ID?",
                    name: "ReservationSessionPrescriptions",
                    component: () =>
                        import (
                            "@/views/Patient/Reservation/ReservationSessionPrescriptions.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "ReservationSessionPrescriptions", active: true }
                        ],
                        pageTitle: "Prescriptions",
                        rule: "patient"
                    }
                },
                {
                    path: "/Patient/InComingSurgeries/:ID?",
                    name: "InComingSurgeries",
                    component: () =>
                        import (
                            "@/views/Patient/PatientSurgeries&Treatments/InComingSurgeries.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "InComingSurgeries", active: true }
                        ],
                        pageTitle: "Surgeries",
                        rule: "patient"
                    }
                },
                {
                    path: "/TreatmentsProfile/:ID?/:ViewTypeID?/:PatientReservationSurgeryID?",
                    name: "TreatmentsProfile",
                    component: () =>
                        import (
                            "@/views/Patient/PatientSurgeries&Treatments/TreatmentsProfile.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "TreatmentsProfile", active: true }
                        ],
                        pageTitle: "TreatmentsProfile",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/Patient/OldSurgeries/:ID?",
                    name: "OldSurgeries",
                    component: () =>
                        import (
                            "@/views/Patient/PatientSurgeries&Treatments/OldSurgeries.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "OldSurgeries", active: true }
                        ],
                        pageTitle: "Surgeries",
                        rule: "patient"
                    }
                },
                {
                    path: "/Patient/PatientSurgeries&Treatments/PatientSurgeriesTabs/:ID?",
                    name: "PatientSurgeries",
                    component: () =>
                        import (
                            "@/views/Patient/PatientSurgeries&Treatments/PatientSurgeriesTabs.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "PatientSurgeries", active: true }
                        ],
                        pageTitle: "Patient Surgeries",
                        rule: "patient"
                    }
                },
                // start Pending medical documentations mkl
                {
                    path: "/pendingMedicalReport/:ID?",
                    name: "pendingMedicalReport",
                    component: () =>
                        import ("@/views/patientService/pendingMedicalReportTabs.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient Medical Report", active: true }
                        ],
                        pageTitle: "Patient Medical Report",
                        rule: "anonymous"
                    }
                },
                // end Pending medical AddHospitalSpecialites
                // start Patient Medical History Session mkl
                {
                    path: "/PatientMedicalHistorySession/:ID?",
                    name: "PatientMedicalHistorySession",
                    component: () =>
                        import ("@/views/doctor/Patients/PatientMedicalHistory.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient Medical History", active: true }
                        ],
                        pageTitle: "Patient Medical History",
                        rule: "hospitaldoctor"
                    }
                },
                // end Patient Medical Session History

                {
                    path: "/ChangePassword",
                    name: "ChangePassword",
                    component: () =>
                        import ("@/views/changePassword.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "ChangePassword", active: true }
                        ],
                        pageTitle: "Change Password",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/patientServices",
                    name: "patientServices",
                    component: () =>
                        import ("@/views/patientService/patientService.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patients' Services", active: true }
                        ],
                        pageTitle: "ServicesProvidedToOurPatients",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/DoctorRate/:DoctorID?/:PatientReserviedSessionID?",
                    name: "DoctorRate",
                    component: () =>
                        import ("@/views/doctor/DoctorRate.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Doctor' Rate", active: true }
                        ],
                        pageTitle: "Doctor' Rate",
                        rule: "patient"
                    }
                },
                {
                    path: "/HospitalRate/:HospitalID?/:PatientReseriedSurgeryID?",
                    name: "DoctorRate",
                    component: () =>
                        import ("@/views/hospital/HospitalRate.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Doctor' Rate", active: true }
                        ],
                        pageTitle: "Doctor' Rate",
                        rule: "patient"
                    }
                },
                {
                    path: "/travelArrangments",
                    name: "travelArrangments",
                    component: () =>
                        import ("@/views/patientService/travelArrangment.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Travel arrangements", active: true }
                        ],
                        pageTitle: "ServicesProvidedToOurPatients",
                        rule: "anonymous"
                    }
                },

                {
                    path: "/TravelarrangementsDialog",
                    name: "TravelarrangementsDialog",
                    component: () =>
                        import ("@/views/patientService/TravelarrangementsDialog.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Travel arrangements' Dialog", active: true }
                        ],
                        pageTitle: "Travel arrangements' Dialog",
                        rule: "anonymous"
                    }
                },

                {
                    path: "/PaymentAlertDialog",
                    name: "PaymentAlertDialog",
                    component: () =>
                        import ("@/views/Patient/PaymentAlertDialog.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Payment Alert ' Dialog", active: true }
                        ],
                        pageTitle: "Payment Alert ' Dialog",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/ExtraService",
                    name: "ExtraService",
                    component: () =>
                        import ("@/views/patientService/ExtraService.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Extra Service", active: true }
                        ],
                        pageTitle: "Extra Service",
                        rule: "anonymous"
                    }
                },

                //payment part

                // {
                //   path: "/ConfirmPayment",
                //   name: "ConfirmPayment",
                //   component: () => import("@/views/Payment/ConfirmPayment.vue"),
                //   meta: {
                //     breadcrumb: [
                //       { title: "Home", url: "/" },
                //       { title: "Confirm Payment", active: true }
                //     ],
                //     pageTitle: "Confirm Payment",
                //     rule: "anonymous"
                //   }
                // },

                {
                    path: "/Extras",
                    name: "Extras",
                    component: () =>
                        import ("@/views/Patient/PatientSurgeries&Treatments/Extras.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Travel arrangements' Dialog", active: true }
                        ],
                        pageTitle: "Travel arrangements' Dialog",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/Travelarrangements",
                    name: "Travelarrangements",
                    component: () =>
                        import (
                            "@/views/Patient/PatientSurgeries&Treatments/Travelarrangements.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Travel arrangements' Dialog", active: true }
                        ],
                        pageTitle: "Travel arrangements' Dialog",
                        rule: "anonymous"
                    }
                },

                // patientProfile
                {
                    path: "/CompletedRegisterationData/:ID?",
                    name: "CompletedRegisterationData",
                    component: () =>
                        import ("@/views/patientProfile/CompletedRegisterationData.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Completed Registeration", active: true }
                        ],
                        pageTitle: "Completed Registeration",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/homePatientt",
                    name: "homePatient",
                    component: () =>
                        import ("@/views/patientProfile/homePatientt.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Travel Arrangments", active: true }
                        ],
                        pageTitle: "Travel Arrangments",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/PatientMedicalReport",
                    name: "PatientMedicalReport",
                    component: () =>
                        import ("@/views/Patient/PatientMedicalReport.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Travel Arrangments", active: true }
                        ],
                        pageTitle: "Travel Arrangments",
                        rule: "patient"
                    }
                },
                {
                    path: "/Patient/PatientSetting",
                    name: "PatientSetting",
                    component: () =>
                        import ("@/views/Patient/PatientSetting.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "PatientSetting", active: true }
                        ],
                        pageTitle: "PatientSetting",
                        rule: "patient"
                    }
                },
                {
                    path: "/Patient/Surgeryprofile/:ID?/:StatusID?",
                    name: "Surgeryprofile",
                    component: () =>
                        import (
                            "@/views/Patient/PatientSurgeries&Treatments/Surgeryprofile.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Surgeryprofile", active: true }
                        ],
                        pageTitle: "Surgery profile",
                        rule: "patient"
                    }
                },
                {
                    path: "/PatientMedicalReport",
                    name: "PatientMedicalReport",
                    component: () =>
                        import ("@/views/Patient/PatientMedicalReport.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Travel Arrangments", active: true }
                        ],
                        pageTitle: "Travel Arrangments",
                        rule: "patient"
                    }
                },
                {
                    path: "/DoctorAddAvailability",
                    name: "DoctorAddAvailability",
                    component: () =>
                        import ("@/views/doctor/Appointment/DoctorAddAvailability.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Add Availability", active: true }
                        ],
                        pageTitle: "Add Availability",
                        rule: "doctor"
                    }
                },
                {
                    path: "/DoctorEditAvailability",
                    name: "DoctorEditAvailability",
                    component: () =>
                        import ("@/views/doctor/Appointment/DoctorEditAvailability.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Edit Availability", active: true }
                        ],
                        pageTitle: "Edit Availability",
                        rule: "doctor"
                    }
                },
                {
                    path: "/DoctorYearSummaryAppointments",
                    name: "DoctorYearSummaryAppointments",
                    component: () =>
                        import ("@/views/doctor/Appointment/DoctorYearSummaryAppointments.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Doctor Year Summary Appointments", active: true }
                        ],
                        pageTitle: "Doctor Year Summary Appointments",
                        rule: "doctor"
                    }
                },
                {
                    path: "/SurgeryAndTreatment/:CountryID?/:SpecialtyID?",
                    name: "SurgeryAndTreatment",
                    component: () =>
                        import ("@/views/Patient/Surgery/SurgeryAndTreatment.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Surgery & Treatment", active: true }
                        ],
                        pageTitle: "SurgeryTreatment",
                        rule: "anonymous"
                    }
                },

                //  end patientProfile
                {
                    path: "/doctor/Home",
                    name: "doctorLandingPage",

                    component: () =>
                        import ("@/views/doctor/Home.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Landing Page", active: true }
                        ],
                        pageTitle: "Landing Page",
                        rule: "doctor"
                    }
                },

                // //doctorProfile
                // {
                //   path: "/doctor/MInInfoo",

                //   component: () => import("@/views/doctorProfile/MInInfoo.vue"),
                //   meta: {
                //     breadcrumb: [
                //       { title: "Home", url: "/" },
                //       { title: "Landing Page", active: true }
                //     ],
                //     pageTitle: "Landing Page",
                //     rule: "anonymous"
                //   }
                // },

                //doctorProfile
                {
                    path: "/DoctorProfileData",
                    name: "DoctorProfileData",
                    component: () =>
                        import ("@/views/doctorProfile/DoctorProfileData.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Doctor Profile Data", active: true }
                        ],
                        pageTitle: "Doctor Profile Data",
                        rule: "doctor"
                    }
                },
                {
                    path: "/CompletedRegisterationDoctorData/:ID?",
                    name: "CompletedRegisterationDoctorData",
                    component: () =>
                        import (
                            "@/views/doctorProfile/CompletedRegisterationDoctorData.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Completed Registeration Data", active: true }
                        ],
                        pageTitle: "Completed Registeration Data",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/medicalHistory",
                    name: "medicalHistory",
                    component: () =>
                        import ("@/views/doctorProfile/medicalHistory.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Travel Arrangments", active: true }
                        ],
                        pageTitle: "Travel Arrangments",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/DoctorWeeklyAppointments",
                    name: "DoctorWeeklyAppointments",
                    component: () =>
                        import ("@/views/doctor/Appointment/DoctorWeeklyAppointments.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "doctor Weekly Online Appointments", active: true }
                        ],
                        pageTitle: "doctor Weekly Online Appointments",
                        rule: "doctor"
                    }
                },
                {
                    path: "/PatientMedicalHistory/:ID?",

                    name: "PatientMedicalHistory",
                    component: () =>
                        import ("@/views/doctor/Patients/PatientMedicalHistory.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient Medical History", active: true }
                        ],
                        pageTitle: "Patient Medical History",
                        rule: "hospitaldoctor"
                    }
                },

                {
                    path: "/PatientDiagnosisPrescription/:patientReservationSessionID?/:patientID?",
                    name: "PatientDiagnosisPrescription",
                    component: () =>
                        import (
                            "@/views/doctor/Patients/PatientDiagnosisAndPrescription.vue"
                        ),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient Diagnosis & Prescription ", active: true }
                        ],
                        pageTitle: "Patient Diagnosis & Prescription ",
                        rule: "doctor"
                    }
                },

                {
                    path: "/PatientSymptomsDescription/:PatientReservationID?",
                    name: "PatientsSymptomsDescription",
                    component: () =>
                        import ("@/views/doctor/Patients/PatientsSymptomsDescription.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient's Symptoms Description", active: true }
                        ],
                        pageTitle: "Patient's Symptoms Description",
                        rule: "doctor"
                    }
                },
                {
                    path: "/AddPatientSymptomsDescription/:PatientReservationID?",

                    name: "AddPatientSymptomsDescription",
                    component: () =>
                        import ("@/views/doctor/Patients/AddPatientSymptomsDescription.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient's Symptoms Description", active: true }
                        ],
                        pageTitle: "Patient's Symptoms Description",
                        rule: "doctor"
                    }
                },

                {
                    path: "/doctor/DoctorSessionDetails/:PatientReservationID?/:IsDoctorRequest?",
                    name: "DoctorSessionDetails",
                    component: () =>
                        import ("@/views/doctor/Appointment/DoctorSessionDetails.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Doctor Session Details", active: true }
                        ],
                        pageTitle: "Doctor Session Details",
                        rule: "doctor"
                    }
                },

                // end doctorProfile

                // start doctor patient reservation mkl
                {
                    path: "/PatientReservation/:DoctorID?/:PatientID?",
                    name: "PatientReservation",
                    component: () =>
                        import ("@/views/doctorProfile/PatientReservation.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Patient History", active: true }
                        ],
                        pageTitle: "Patient History",
                        rule: "doctor"
                    }
                },
                {
                    path: "/YourPatient/:ID?",
                    name: "YourPatient",
                    component: () =>
                        import ("@/views/doctorProfile/DoctorPatient.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Your Patient", active: true }
                        ],
                        pageTitle: "Your Patient",
                        rule: "doctor"
                    }
                },
                {
                    path: "/doctor/DoctorSetting",
                    name: "DoctorSetting",
                    component: () =>
                        import ("@/views/doctor/DoctorSetting.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Setting", active: true }
                        ],
                        pageTitle: "Setting",
                        rule: "doctor"
                    }
                },
                // end doctor patient reservation
                // start Pending medical Reports Download documentations mkl
                // {
                //  path: "/pendingMedicalReportsDownload/:patientID?/:DoctorID?",
                // name: "pendingMedicalReportsDownload",
                // component: () => import("@/views/doctorProfile/pendingMedicalReportsDownload.vue"),
                // meta: {
                //  breadcrumb: [
                //    { title: "Home", url: "/" },
                //    { title: "pending Medical Reports Download", active: true }
                //  ],
                //  pageTitle: "pending Medical Reports Download",
                //  rule: "anonymous"
                // }
                // },
                // end Pending medical Reports Download documentations

                // start doctor online Appointment mkl
                {
                    path: "/onlineAppointment/:ID?",
                    name: "onlineAppointment",
                    component: () =>
                        import ("@/views/doctorProfile/onlineAppointment.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "online Appointment", active: true }
                        ],
                        pageTitle: "online Appointment",
                        rule: "anonymous"
                    }
                },
                // end doctor online Appointment
                // start doctor pending Task mkl
                {
                    path: "/pendingTaskDoctor/:ID?",
                    name: "pendingTaskDoctor",
                    component: () =>
                        import ("@/views/doctorProfile/pendingTaskDoctor.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "pending Task Doctor", active: true }
                        ],
                        pageTitle: "pending Task Doctor",
                        rule: "anonymous"
                    }
                },
                // end doctor pending Task
                {
                    path: "/DoctorBlockAlert",
                    name: "DoctorBlockAlert",
                    component: () =>
                        import ("@/views/doctor/manage/DoctorBlockAlert.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: " Block Alert", active: true }
                        ],
                        pageTitle: "Block Alertn",
                        rule: "anonymous"
                    }
                },
                // end doctor patient reservation

                //hospital
                {
                    path: "/hospital/Home",
                    name: "hospitalLandingPage",
                    component: () =>
                        import ("@/views/hospital/Home.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Landing Page", active: true }
                        ],
                        pageTitle: "Landing Page",
                        rule: "hospital"
                    }
                },
                {
                    path: "/hospital/ReservedSurgery",
                    name: "ReservedSurgery",
                    component: () =>
                        import ("@/views/hospital/ReservedSurgery.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Reserved Surgery", active: true }
                        ],
                        pageTitle: "Reserved Surgery",
                        rule: "hospitaldoctor"
                    }
                },

                {
                    path: "/hospital/Add-EditTreatmentSurgery/:HospitalSurgeryID?",
                    name: "AddEditTreatmentSurgery",
                    component: () =>
                        import ("@/views/hospital/AddEditTreatmentSurgery.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Add Treatment Surgery", active: true }
                        ],
                        pageTitle: "Add Treatment Surgery",
                        rule: "hospital"
                    }
                },
                {
                    path: "/hospital/AddTreatmentSurgery",
                    name: "AddTreatmentSurgery",
                    component: () =>
                        import ("@/views/hospital/AddTreatment.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Add Treatment Surgery", active: true }
                        ],
                        pageTitle: "Add Treatment Surgery",
                        rule: "hospital"
                    }
                },
                {
                    path: "/hospital/Doctor",
                    name: "hospitalDoctor",
                    component: () =>
                        import ("@/views/hospital/ViewHospitalDoctor.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "hospital Doctor", active: true }
                        ],
                        pageTitle: "hospital Doctor",
                        rule: "hospital"
                    }
                },
                {
                    path: "/hospital/Specialty",
                    name: "hospitalSpecialty",
                    component: () =>
                        import ("@/views/hospital/viewHospitalSpecialty.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "hospital Specialty", active: true }
                        ],
                        pageTitle: "hospital Specialty",
                        rule: "hospital"
                    }
                },
                {
                    path: "/hospital/AddHospitalSpecialites",
                    name: "AddHospitalSpecialites",
                    component: () =>
                        import ("@/views/hospital/AddHospitalSpecialites.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Add Hospital Specialites", active: true }
                        ],
                        pageTitle: "Add Hospital Specialites",
                        rule: "hospital"
                    }
                },
                {
                    path: "/hospital/AddHospitalSubSpecialites",
                    name: "AddHospitalSubSpecialites",
                    component: () =>
                        import ("@/views/hospital/AddHospitalSubSpecialites.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Add Hospital Sub Specialites", active: true }
                        ],
                        pageTitle: "Add Hospital Sub Specialites",
                        rule: "hospital"
                    }
                },
                {
                    path: "/hospital/AddHospitalDoctors",
                    name: "AddHospitalDoctors",
                    component: () =>
                        import ("@/views/hospital/AddHospitalDoctors.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Add Hospital Doctors", active: true }
                        ],
                        pageTitle: "Add Hospital Doctors",
                        rule: "hospital"
                    }
                },
                {
                    path: "/hospital/AddHospitalSurgeryDoctors",
                    name: "AddHospitalSurgeryDoctors",
                    component: () =>
                        import ("@/views/hospital/AddHospitalSurgeryDoctors.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Add Hospital Surgery Doctors", active: true }
                        ],
                        pageTitle: "Add Hospital Surgery Doctors",
                        rule: "hospital"
                    }
                },

                {
                    path: "/hospital/HospitalSetting",
                    name: "HospitalSetting",
                    component: () =>
                        import ("@/views/hospital/HospitalSetting.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Hospital Setting", active: true }
                        ],
                        pageTitle: "Hospital Setting",
                        rule: "hospital"
                    }
                },
                {
                    path: "/hospitalInfo",
                    name: "hospitalInfo",
                    component: () =>
                        import ("@/views/hospital/hospitalInfo.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Travel Arrangments", active: true }
                        ],
                        pageTitle: "Travel Arrangments",
                        rule: "anonymous"
                    }
                },

                {
                    path: "/CreateDoctorProfile",
                    name: "CreateDoctorProfile",
                    component: () =>
                        import ("@/views/hospital/CreateDoctorProfile.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Create Doctor Profile ", active: true }
                        ],
                        pageTitle: "Create Doctor Profile",
                        rule: "anonymous"
                    }
                },

                {
                    path: "/hospitaldetails",
                    name: "hospitaldetails",
                    component: () =>
                        import ("@/views/hospital/hospitaldetails.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "hospital", active: true }
                        ],
                        pageTitle: "hospital",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/CompletedRegisterationHospital/:Id?",
                    name: "CompletedRegisterationHospital",
                    component: () =>
                        import ("@/views/hospital/hospitaldetails.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "hospital", active: true }
                        ],
                        pageTitle: "hospital",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/UpdateHospitalProfile/:Id?",
                    name: "UpdateHospitalProfile",
                    component: () =>
                        import ("@/views/hospital/hospitaldetails.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "hospital", active: true }
                        ],
                        pageTitle: "hospital",
                        rule: "anonymous"
                    }
                },
                //end hospital
                {
                    path: "/DoctorSearch/:CountryID?/:SpecialtyID?",
                    name: "DoctorSearch",
                    component: () =>
                        import ("@/views/doctor/search/DoctorSearch.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Doctor Search", active: true }
                        ],
                        pageTitle: "DoctorAndOnlineConsultation",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/AppointmentSearch/:DoctorID?/:CountryID?/:SpecialtyID?",
                    name: "AppointmentSearch",
                    component: () =>
                        import ("@/views/doctor/search/DoctorSearch.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Appointment Search", active: true }
                        ],
                        pageTitle: "DoctorAndOnlineConsultation",
                        rule: "anonymous"
                    }
                },

                , {
                    path: "/BookingSummary/:Flag?/:ID?",
                    name: "BookingSummary",
                    component: () =>
                        import ("@/views/appointment/search/SummaryBooking.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Booking Summary", active: true }
                        ],
                        pageTitle: "Booking Summary",
                        rule: "patient"
                    }
                },
                {

                    path: "/HospitalProfile/:ID?",
                    name: "HospitalProfile",
                    component: () =>
                        import ("@/views/pages/Profiles/HospitalProfileTabs.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "HospitalProfile "
                    }
                },
                {

                    path: "/DoctorProfile/:ID?/:FromSurgery?",
                    name: "DoctorProfile",
                    component: () =>
                        import ("@/views/pages/Profiles/DoctorProfile.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Doctor Profile"
                    }
                },

                {
                    path: "/PackageDetail/:PackageID?",
                    name: "PackageDetail",
                    component: () =>
                        import ("@/views/package/PackageDetail.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Package Detail", active: true }
                        ],
                        pageTitle: "Package Detail",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/ReservedPackageDetail/:PackageID?",
                    name: "ReservedPackageDetail",
                    component: () =>
                        import ("@/views/Patient/Packages/ReservedPackageDetail.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "Reserved Package Detail", active: true }
                        ],
                        pageTitle: "Reserved Package Detail",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/FAQ",
                    name: "FAQ",
                    component: () =>
                        import ("@/views/FAQPopup.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "FAQ",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/AboutUs",
                    name: "AboutUs",
                    component: () =>
                        import ("@/views/AboutUs.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "AboutUs",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/CollaborateDoctor",
                    name: "CollaborateDoctor",
                    component: () =>
                        import ("@/views/CollaborateDoctor.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "CollaborateDoctor",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/CollaborateHospital",
                    name: "CollaborateHospital",
                    component: () =>
                        import ("@/views/CollaborateHospital.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "CollaborateHospital",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/CollaborateJoinOurTeam",
                    name: "CollaborateJoinOurTeam",
                    component: () =>
                        import ("@/views/CollaborateJoinOurTeam.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "CollaborateJoinOurTeam",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/CollaborateStrategic",
                    name: "CollaborateStrategic",
                    component: () =>
                        import ("@/views/CollaborateStrategic.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "CollaborateStrategic",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/Collaborate",
                    name: "Collaborate",
                    component: () =>
                        import ("@/views/Collaborate.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "Collaborate",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/Ar/FAQ",
                    name: "FAQ",
                    component: () =>
                        import ("@/views/FAQPopupAr.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "FAQ",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/TermsAndCondetions",
                    name: "TermsAndCondetions",
                    component: () =>
                        import ("@/views/TermsAndCondetionsPopup.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "TermsAndCondetions",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/TermsAndCondetionsAr",
                    name: "TermsAndCondetionsAr",
                    component: () =>
                        import ("@/views/TermsAndCondetionsPopupAr.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "TermsAndCondetions",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/PatientWaiver",
                    name: "PatientWaiver",
                    component: () =>
                        import ("@/views/PatientWaiverPopup.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "PatientWaiver",
                        rule: "anonymous"
                    }
                },
                {
                    path: "/Ar/PatientWaiver",
                    name: "ArPatientWaiver",
                    component: () =>
                        import ("@/views/PatientWaiverPopupAr.vue"),
                    meta: {
                        breadcrumb: [

                        ],
                        pageTitle: "PatientWaiver",
                        rule: "anonymous"
                    }
                }
            ]
        },

        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: "",
            component: () =>
                import ("@/layouts/full-page/FullPage.vue"),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                // {
                //     path: '/callback',
                //     name: 'auth-callback',
                //     component: () => import('@/views/Callback.vue'),
                //     meta: {
                //         rule: 'editor'
                //     }
                // },
                {
                    path: "/pages/login",
                    name: "page-login",
                    component: () =>
                        import ("@/views/pages/login/Login.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Login"
                    }
                },

                {
                    path: "/pages/register/:UserTypeID?", //1-patient 2-doctor 3-hospital
                    name: "page-register",
                    component: () =>
                        import ("@/views/pages/register/Register.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Register "
                    }
                },
                {
                    path: "/pages/ContactUs",
                    name: "page-ContactUs",
                    component: () =>
                        import ("@/views/pages/contactUs/ContactUs.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Contact Us "
                    }
                },


                {
                    path: "/registration/registerType",
                    name: "registerType",
                    component: () =>
                        import ("@/views/pages/register/RegisterType.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Register "
                    }
                },

                {
                    path: "/pages/forgot-password",
                    name: "page-forgot-password",
                    component: () =>
                        import ("@/views/pages/ForgotPassword.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Forgot Password "
                    }
                },
                {
                    path: "/pages/reset-password",
                    name: "page-reset-password",
                    component: () =>
                        import ("@/views/pages/ResetPassword.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "Reset Password "
                    }
                },
                {
                    path: "/pages/lock-screen",
                    name: "page-lock-screen",
                    component: () =>
                        import ("@/views/pages/LockScreen.vue"),
                    meta: {
                        rule: "editor",
                        pageTitle: "Lock Screen "
                    }
                },

                {
                    path: "/pages/error-404",
                    name: "page-error-404",
                    component: () =>
                        import ("@/views/pages/Error404.vue"),
                    meta: {
                        rule: "anonymous"
                    }
                },
                {
                    path: "/pages/onilnepayment",
                    name: "onilnepayment",
                    component: () =>
                        import ("@/views/pages/onilnepayment.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "payment "
                    }
                },
                {
                    path: "/pages/payment",
                    name: "onilnepayment",
                    component: () =>
                        import ("@/views/pages/payment.vue"),
                    meta: {
                        rule: "anonymous",
                        pageTitle: "payment "
                    }
                },
                {
                    path: "/pages/error-500",
                    name: "page-error-500",
                    component: () =>
                        import ("@/views/pages/Error500.vue"),
                    meta: {
                        rule: "editor"
                    }
                },
                {
                    path: "/pages/not-authorized",
                    name: "page-not-authorized",
                    component: () =>
                        import ("@/views/pages/NotAuthorized.vue"),
                    meta: {
                        rule: "anonymous"
                    }
                },
                {
                    path: "/MainInfo",
                    name: "page-not-authorized",
                    component: () =>
                        import ("@/views/patientProfile/CompletedRegisterationData.vue"),
                    meta: {
                        rule: "anonymous"
                    }
                },

                {
                    path: "/pages/maintenance",
                    name: "page-maintenance",
                    component: () =>
                        import ("@/views/pages/Maintenance.vue"),
                    meta: {
                        rule: "editor",

                    }
                }
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: "*",
            redirect: "/pages/error-404"
        }
    ]
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

router.beforeEach((to, from, next) => {
    // firebase.auth().onAuthStateChanged(() => {

    //     // get firebase current user
    //     const firebaseCurrentUser = firebase.auth().currentUser

    // if (
    //     to.path === "/pages/login" ||
    //     to.path === "/pages/forgot-password" ||
    //     to.path === "/pages/error-404" ||
    //     to.path === "/pages/error-500" ||
    //     to.path === "/pages/register" ||
    //     to.path === "/callback" ||
    //     to.path === "/pages/comingsoon" ||
    //     (auth.isAuthenticated() || firebaseCurrentUser)
    // ) {
    //     return next();
    // }

    // If auth required, check login. If login fails redirect to login page

    if (to.meta.authRequired) {
        if (!auth.isAuthenticated()) {
            router.push({ path: "/pages/login", query: { to: to.path } });
        }
    }

    return next();
    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });

    //});
});

export default router;
